<template>
  <div class="bg-white">
    <NavBarNew></NavBarNew>
    <NewsComponent></NewsComponent>

    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
// import NewsComponent from "@/components/News/NewsComponent.vue";
import NewsComponent from "@/components/News/TwitterFeed.vue";
import Footer from "@/components/HomeScreen/Footer.vue";

//import Header from '@/components/HomeScreen/header.vue'

export default {
  name: "News",
  components: {
    NavBarNew,
    NewsComponent,
    Footer,
  },
};
</script>