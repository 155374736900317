<template>
  <div class="pt-40 md:px-24 grid grid-flow-row md:grid-cols-2">
    <div id="curator-feed-default-feed-layout">
      <a href="https://curator.io" target="_blank" class="crt-logo crt-tag"></a>
    </div>
    <div class=" ml-8">
      <div class="border-StemPurple border-l-2 pl-4">
        <h1 class="text-StemBlue font-bold text-4xl pr-4 text-left">
          Arritjet
        </h1>
      </div>
      <div class="pt-10 grid grid-flow-row place-content-center gap-y-4">
        <div class="grid grid-cols-2">
          <img
            src="./../../assets/images/achivements/ach1.png"
            class="divide-x-8"
            alt=""
          />
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            Vetëm një vit pas themelimit STEMLab merr pjesë në EUROPE CODE WEEK
            2019 me tri projekte: Robust Buildings - in the category Girls do
            IT! – Shkolla “Hasan Tahsini” – Rogle, "Safe Driving" - Community
            category! – Shkolla “Migjeni” – Tetovë dhe “#AMyZONE” në bashkëpunim
            me Njësinë e Zjarrëfikësve Tetovë.
          </h2>
        </div>
        <div class="grid grid-cols-2">
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            STEMLab me Projektin “#AMyZONE” kulaifikohet në finalen e Meet and
            Code, në kategorinë Code for The Planet! Është me rëndësi të
            theksohet se për herë të pare një institucion nga Maqedonia arrinë
            në këtë finale!
            <br>
            <a target="blank" href="https://mioa.gov.mk/?q=sq/node/2847" class=" text-StemLightRed float-left">Shiko linkun</a>
            <br>
            <a target="blank" href="https://meet-and-code.org/ch/en/event-show/2868" class=" text-StemLightRed float-left">Shiko linkun</a>
            
          </h2>
          <img src="./../../assets/images/achivements/ach2.png" alt="" />
        </div>
        <div class="grid grid-cols-2">
          <img src="./../../assets/images/achivements/ach8.png" alt="" />
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            STEMLab është institucioni i parë në regjion i akredituar për STEM
            edukim nga stem.org. Kredencialet e akreditimit gjenden në linkun në
            vijim.
            <br>
            <a target="blank" href="https://www.credential.net/7914ae5f-0c3b-42e0-bd7e-4ef8723e8139" class=" text-StemLightRed float-right">Shiko linkun</a>
            
          </h2>
        </div>
        <div class="grid grid-cols-2">
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            Me projektin #STEMSFIDA, ku gjatë periudhës së karantinimit nxënësit
            me mjete shtëpiake kanë realizuar eksperimente nga STEM profilet dhe
            videot e tyre janë shpërndarë në rrjetet sociale, STEMLab arrinë në
            finalen e Science Breakthrough of the Year në kategorinë Science
            Engagement Initiatives at Falling Walls Remote 2020 – Berlin.
            <br>
            <a target="blank" href="https://falling-walls.com/people/ramadan-aliti/" class=" text-StemLightRed float-left">Shiko linkun</a>
            
          </h2>
          <img src="./../../assets/images/achivements/ach4.png" alt="" />
        </div>

        <div class="grid grid-cols-2">
          <img
            src="./../../assets/images/achivements/1.jpg"
            alt="zvnw ft nr2"
          />
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            Festivali i shtatë ndërkombëtar i Brainobrain Fest u mbajt online më
            29 janar 2022. Brainobrain nxënësit nga Tetova të moshës 4 deri në
            14 vjeç që ndjekin trajnimet në STEMLab, në konkurrencë me më shumë
            se 23.000 fëmijë nga 72 vende të botës fituan: 3 trofe, 2 medalje
            ari dhe 6 medalje argjendi.
            <br>
            <a target="blank" href="http://bonzo.info/2022/02/16/nxenesit-e-stemlab-tetove-treguan-sukses-te-jashtezakonshem-ne-garat-me-te-medha-nderkombetare-te-femijeve-brainobrain-fest-2022/" class=" text-StemLightRed float-right">Shiko linkun</a>
            
          </h2>
        </div>

        <div class="grid grid-cols-2">
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            STEMLab është institucioni i parë në regjion me dy trajner të
            çertifikuar në STEM edukim. Prof. Besmal Memedi Prof. Ramadan Aliti
            Kredencialet e çertifikimit janë paraqitur më poshtë:
            <br>
            <a target="blank" href="https://blockchain.stem.org/bf871676-26f7-4709-8bc5-cc7aa438e482#gs.yiu8cu" class=" text-StemLightRed float-left">Shiko linkun</a>
            <br>
            <a target="blank" href="https://www.credential.net/a900713b-ae73-4123-84fa-6cf137b02545#gs.yiu8sr" class=" text-StemLightRed float-left">Shiko linkun</a>
          </h2>
          <img
            src="./../../assets/images/achivements/4.jpg"
            alt="me foton nr 4 "
          />
        </div>
        <div class="grid grid-cols-2">
          <img src="./../../assets/images/achivements/5.jpg" alt="" />
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            #STEMLab - pjesë e diskutimit tematik të organizuar nga Ministri i
            Punëve të Jashtme z. Bujar Osmani, strategjisë 10 vjeçare të
            emërtuar si #reflektime2030 (#refleksija2030). Fokus kryesor i këtij
            diskutimi është shkëmbimi i mendimeve mbi nevojën për promovimit
            ndërkombëtar të potencialit kombëtar të inovacionit dhe rolin e
            komunitetit të inovacionit në zhvillimin dhe zbatimin e strategjisë
            së politikës së jashtme të Republikës së Maqedonisë Veriore.
          </h2>
        </div>
        <div class="grid grid-cols-2">
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            Në bashkëpunim me Këndin Amerikan Tetovë, STEMLab realizon Kampin
            “Renewable Energy” në kuadër të STEM WEEK.
            <br>
            <a target="blank" href="https://americancornersnmk.org/tetovo/event/178" class=" text-StemLightRed float-left">Shiko linkun</a>
          </h2>
          <img src="./../../assets/images/achivements/6.jpg" alt="" />
        </div>
        <div class="grid grid-cols-2">
          <img src="./../../assets/images/achivements/7.jpg" alt="" />
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            STEMLab pjesë e konferencës ndërkombëtare “International Dialogue on
            STEM Education” (IDoS) – Berlin 2019. Prof. Ramadan Aliti,
            nënshkrues i Position Paper “Using Science to Do Social Good: STEM
            Education for Sustainable Development”
            <br>
            <a target="blank" href="https://www.haus-der-kleinen-forscher.de/fileadmin/Redaktion/Englisch/IDoS/2019_Positionpaper_STEM4SD_Education_Dec_6.pdf" class=" text-StemLightRed float-right">Shiko linkun</a>
          </h2>
        </div>
        <div class="grid grid-cols-2">
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            STEMLab - ORGANIZON PUNTORINË “STEM EDUCATION FOR THE FUTURE” The
            STEM - State of Mind (Hyrje në STEM edukim) SMART Goals and Growth
            Plan (Planifikimi i STEM orës/aktivitetit) 2 STEM activities (4C
            methodology) (Realizimi i 2 STEM aktivitetetve të bazuar në
            metodologjinë 4C) STEM resources and evaluation (STEM burimet dhe
            evaluimi)
          </h2>
          <img src="./../../assets/images/achivements/8.jpg" alt="" />
        </div>
        <div class="grid grid-cols-2">
          <img src="./../../assets/images/achivements/9.jpg" alt="" />
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            STEMLab pjesë integrale e INTERNATIONAL STEM EDUCATION CONFERENCE –
            Turqi.
            <br>
            <a target="blank" href="https://www.stempd.net/committees/" class=" text-StemLightRed float-right">Shiko linkun</a>
          </h2>
        </div>
        <div class="grid grid-cols-2">
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            Një përshkrim i idesë së STEMLab i paraqitur në portalin BonZo
            <br>
            <a href="http://bonzo.info/2021/03/22/ramadan-aliti-stemlab-eshte-institucioni-i-pare-ne-maqedoni-pjese-e-finales-se-falling-walls-berlin-te-vitit-2020/?fbclid=IwAR29PpbwO0zTQy9HwRi80qEpdfr2xvB0IGNzcMgIyedN9AP9L1FxxBuwlpI" class=" text-StemLightRed float-left"> Shiko Linkun</a>
            
          </h2>
          <img src="./../../assets/images/achivements/11.png" alt="" />
        </div>
        <div class="grid grid-cols-2">
          <img src="./../../assets/images/achivements/12.png" alt="" />
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            Në vitin 2021 STEMLab realizoi projektin e titulluar “Da Vinci”, i
            cili kishtë për qëllim që të stimuloj bashkëpunimin mes artit dhe
            shkencës dhe të arrihen krijime artistike të frymëzuara nga dukuritë
            natyrore dhe shkenca në veçanti. Ky projekt u përkrah nga Ministria
            e Kulturës së Republikës së Maqedonisë.
          </h2>
        </div>
        <div class="grid grid-cols-2">
          <h2 class=" text-StemTextSecond text-sm justify-between place-self-center">
            “Less Noise, More City” – 2020, pilot projekt për matjen e ndotjes
            akustike në rrugët e qytetit të Tetovës. Ky projekt i përkrahur nga
            Ministria e Ambientit dhe Planifikimt Hapsinor të Maqedonisë,
            njëherit ka shërbyer edhe për formulimin e një teme të diplomës për
            ciklin bachelor.
          </h2>
          <img src="./../../assets/images/achivements/13.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>


<script type="text/javascript">
/* curator-feed-default-feed-layout */
(function () {
  var i,
    e,
    d = document,
    s = "script";
  i = d.createElement("script");
  i.async = 1;
  i.charset = "UTF-8";
  i.src = process.env.VUE_APP_CURATOR_TWITTER_URL;
  e = d.getElementsByTagName(s)[0];
  e.parentNode.insertBefore(i, e);
})();
</script>

<style>
</style>